import { Children, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import PlainButton from "./PlainButton";
import { FaX } from "react-icons/fa6";

export default function Popup({ isOpen, onClose, children }) {
  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    if (ref.current != null && !ref.current.contains(e.target)) {
      onClose();
    }
  };

  return (
    <div>
      {createPortal(
        <div
          className={
            isOpen
              ? "fixed size-full top-0 bg-black bg-opacity-50 z-[99] transition-all duration-700"
              : "fixed size-full top-0 bg-black bg-opacity-0 z-[99] transition-all duration-700 pointer-events-none"
          }
        >
          <div
            ref={ref}
            className={
              isOpen
                ? "fixed block bg-igWhite size-[40%] inset-0 m-auto z-[9999] transition-all duration-300"
                : "fixed inset-0 m-auto size-[40%] z-[99999] opacity-0 transition-all duration-300 pointer-events-none"
            }
          >
            <PlainButton>
              <FaX size={20} onClick={()=>onClose()}/>
            </PlainButton>
            {children}
          </div>
        </div>,
        document.body
      )}
    </div>
  );
}

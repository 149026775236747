import { Link } from "react-router-dom";
import profile from "../assets/profile.png";
import { FaArrowRight } from "react-icons/fa";
import PlainButton from "../components/PlainButton";

export default function About() {
  return (
    <div className="m-4 font-Rubik">
      <div className="text-center lg:col-span-1">
        <img
          className="w-[300px] h-[300px] rounded-3xl m-auto object-cover object-top shadow-lg shadow-igGrey"
          src={profile}
        />
        <h1 className="text-xl pt-5">Leticia Fonseca Coutinho</h1>
        <p className="text-igGrey italic text-sm -mt-1 pb-5">
          foto por: Miriane Figueira
        </p>

        <h1 className="font-medium text-xl text-left ml-5">Sobre:</h1>
        <p className="text-balance font-thin text-lg md:text-3xl m-2">
          Oi! 🤗 Eu sou a Leticia (sem acento mesmo), atualmente estou cursando
          Bacharelado em Artes Visuais pela Universidade Federal do Paraná. Eu
          busco, na maioria de minhas produções artísticas, formas de utilizar o
          movimento e o ritmo, tendo influência da percussão afro-brasileira. Os meus interesses no momento, se voltam para a animação e
          a fotografia. 😉
        </p>

        <Link to={"/projects"} className="w-full flex justify-center ">
          <PlainButton>
            <div className="flex justify-center items-center">
              <p className="text-xl p-1">Ver projetos</p>
              <FaArrowRight className="mt-1" />
            </div>
          </PlainButton>
        </Link>

        <Link to={"/contact"} className="w-full flex justify-center ">
          <PlainButton>
            <div className="flex justify-center items-center">
              <p className="text-xl p-1">Entrar em contato</p>
              <FaArrowRight className="mt-1" />
            </div>
          </PlainButton>
        </Link>
      </div>
    </div>
  );
}

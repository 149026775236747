import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function ImageViewer(props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClick = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    if (ref.current != null && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <img className={props.className}  onClick={handleClick} src={props.src} alt="not found" />

      {createPortal(
        <>
          <div
            className={
              isOpen
                ? "fixed size-full top-0 bg-black bg-opacity-50 z-[99] transition-all duration-700 pointer-events-none"
                : "fixed size-full top-0 bg-black bg-opacity-0 z-[99] transition-all duration-700 pointer-events-none"
            }
          ></div>
          <img
            className={
              isOpen
                ? "fixed block max-h-[90%] max-w-[90%] inset-0 m-auto z-[99999999]  transition-all duration-300 pointer-events-none"
                : "fixed inset-0 m-auto z-[99999999] opacity-0 transition-all duration-300 pointer-events-none"
            }
            ref={ref}
            src={props.src}
            alt="not found"
          />
        </>,
        document.body
      )}
    </>
  );
}

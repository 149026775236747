import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Post from "./pages/CreatePost";
import Login from "./pages/Login";
import PostTemplate from "./pages/PostTemplate";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { database } from "./firebase-config";
import { AuthProvider } from "./FirebaseHooks";
import { FaInstagram } from "react-icons/fa";
import { CgMail } from "react-icons/cg";

function App() {
  const [posts, setPosts] = useState("");
  const dbCollection = collection(database, "projects");

  const updatePosts = async () => {
    const dbValue = await getDocs(dbCollection);
    setPosts(dbValue.docs.map((doc) => ({ ...doc.data(), dbId: doc.id })));
  };

  const handlePost = () => {
    updatePosts();
  };

  useEffect(() => {
    updatePosts();
  }, []);

  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Navbar />
          <div className="my-24"></div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/post" element={<Post onPost={handlePost} />} />
            <Route path="*" element={<NotFound />} />
            {posts !== "" &&
              posts.map((pValues) => (
                <Route
                  path={pValues.address}
                  element={
                    <PostTemplate projectId={pValues.id} key={pValues.dbId} />
                  }
                />
              ))}
          </Routes>
          <footer className="bottom-0 inset-x-0 text-sm font-Rubik font-thin text-center mt-28">
            <div>Site desenvolvido por: Fernando Lazzarotto</div>
            <a href="https://www.instagram.com/fsl.chaos/">
              <FaInstagram
                size={15}
                className="inline ml-1 hover:cursor-pointer"
              />
            </a>
            <a href="mailto:fernandosltgr@gmail.com">
              <CgMail size={18} className="inline hover:cursor-pointer" />
            </a>
          </footer>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;


import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {

  apiKey: "AIzaSyDUyIhh6_-QdNvgBgTG_Fkb1_lfFQ01lvY",

  authDomain: "leticia-fons.firebaseapp.com",

  databaseURL: "https://leticia-fons-default-rtdb.firebaseio.com",

  projectId: "leticia-fons",

  storageBucket: "leticia-fons.appspot.com",

  messagingSenderId: "574223507162",

  appId: "1:574223507162:web:ffa8e606ebdb58e2568fe3",

  measurementId: "G-7N5TGDX84R"

};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const database = getFirestore(app);
export const auth = getAuth(app);
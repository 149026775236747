//import Carousel from "react-multi-carousel";
//import "react-multi-carousel/lib/styles.css";
import ImageViewer from "../components/ImageViewer";
import { storage, database } from "../firebase-config";
import { FaArrowLeft } from "react-icons/fa";
import PlainButton from "../components/PlainButton";
import { Link } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import html from "html-react-parser";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import Carousel from "../components/Carousel";

export default function PostTemplate({ projectId }) {
  const [projData, setProjData] = useState("");
  const [projImages, setProjImages] = useState([]);

  const dbCollection = collection(database, "projects");
  const q = query(dbCollection, where("id", "==", projectId));

  useEffect(() => {
    const getData = async () => {
      const dbValue = await getDocs(q);
      setProjData(dbValue.docs[0].data());
    };
    getData();
  }, []);

  useEffect(() => {
    if (projData === "") {
      return;
    }

    listAll(ref(storage, `projectFiles/${projData.id}/`)).then((imgs) => {
      imgs.items.forEach((image) => {
        getDownloadURL(image).then((url) => {
          setProjImages((images) => [...images, url]);
        });
      });
    });
  }, [projData]);

  return (
    <div className="font-Rubik">
      <Link to="/projects">
        <PlainButton>
          <FaArrowLeft size={30} />
        </PlainButton>
      </Link>
      <h1 className="text-4xl font-bold text-center m-10">
        {projData !== "" && projData.title}
      </h1>
      <p className="text-2xl md:text-3xl font-semibold text-center">Mídia:</p>

      <Carousel className="mx-auto my-4  w-[70%] ">
        {projData.videos &&
          projData.videos.map((videoUrl) => (
            <iframe src={videoUrl} frameborder="0" allowFullScreen></iframe>
          ))}
        {projImages.map((dbUrl) => (
          <ImageViewer
            className="flex-initial max-h-[100%] max-w-[80%] "
            src={dbUrl}
          />
        ))}
      </Carousel>

      <p className="text-igGrey text-center italic mt-5">
        clique nas imagens para ver em tela cheia!
      </p>
      <p className="text-2xl md:text-3xl font-semibold m-5 text-center">
        Sobre:
      </p>
      <div className="m-auto break-words ">
        {projData !== "" && html(projData.content)}
      </div>
    </div>
  );
}

import { useEffect, useReducer, useState } from "react";
import { database, storage } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import Card from "../components/Card";
import { GrAdd } from "react-icons/gr";
import { Link } from "react-router-dom";
import PlainButton from "../components/PlainButton";
import { v4 } from "uuid";
import { useAuth } from "../FirebaseHooks";

export default function Projects(props) {
  const [updateNumber, forceUpdate] = useState(0);
  const [projects, setProjects] = useState([]);

  const { isLoggedIn } = useAuth();

  const dbCollection = collection(database, "projects");

  const getData = async () => {
    const dbValue = await getDocs(dbCollection);
    const dbObj = dbValue.docs.map((doc) => ({
      ...doc.data(),
      dbId: doc.id,
    }));
    setProjects([...dbObj]);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleUpdate = () => {
    getData();
  };

  return (
    <>
      {isLoggedIn && (
        <div className="flex justify-center">
          <Link to="/post">
            <PlainButton>
              <GrAdd size={40} />
            </PlainButton>
          </Link>
        </div>
      )}

      <div className="m-6 md:grid md:grid-cols-2 space-x-2">
        {projects !== "" &&
          projects.map(
            (proj) =>
              proj.dbId != "highlighted" && (
                <Card
                  key={v4()}
                  dbId={proj.dbId}
                  projectId={proj.id}
                  img={proj.thumb}
                  title={proj.title}
                  description={proj.desc}
                  update={handleUpdate}
                />
              )
          )}
      </div>
    </>
  );
}

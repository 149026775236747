import { useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";

export default function Drawer({ isOpen, children, onClose }) {
  const ref = useRef(this);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    if (ref != null && !ref.current.contains(e.target)) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={
          isOpen
            ? "fixed size-full top-0 right-0 z-40 bg-black bg-opacity-60 transition-all duration-700 pointer-events-none"
            : "fixed size-full bg-opacity-0 transition-all duration-700 pointer-events-none"
        }
      ></div>

      <div
        className={
          isOpen
            ? "right-0 fixed top-0 w-2/3 h-full ease-in-out duration-700 bg-igRose z-50"
            : "-right-full fixed top-0 w-2/3 ease-in-out h-full duration-700 bg-igRose z-50"
        }
        ref={ref}
      >
        <button
          className="m-5 hover:scale-110 active:bg-black active:bg-opacity-20 rounded-full ease-in-out duration-500 active:duration-200 p-1"
          onClick={onClose}
        >
          <AiOutlineClose size={32} />
        </button>
        {children}
      </div>
    </>
  );
}

import { useState, Children, useEffect, useRef } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

export default function Carousel({ className, children }) {
  const [currentChild, setCurrentChild] = useState(0);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, true);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "ArrowLeft") {
      setCurrentChild((currentChild) => currentChild - 1);
    }
    if (e.key === "ArrowRight") {
      setCurrentChild((currentChild) => currentChild + 1);
    }
  };

  const handleClickRight = () => {
    setCurrentChild((currentChild) => currentChild + 1);
  };

  const handleClickLeft = () => {
    setCurrentChild((currentChild) => currentChild - 1);
  };

  useEffect(() => {
    if (currentChild < 0) {
      setCurrentChild((currentChild) => (currentChild = 0));
    }
    if (currentChild > Children.count(children) - 1) {
      setCurrentChild(
        (currentChild) => (currentChild = Children.count(children) - 1)
      );
    }
  }, [currentChild]);

  return (
    <div className={className}>
      <div className="flex justify-center gap-3 items-center h-[200px] m-auto ">
        <FaArrowCircleLeft
          className={
            currentChild == 0
              ? "flex-none opacity-0 transition-all duration-500 size-[45px] lg:size-[60px]"
              : "flex-none opacity-60 transition-all duration-500 size-[45px] lg:size-[60px]"
          }
          onClick={handleClickLeft}
        />

        {Children.toArray(children)[currentChild]}

        <FaArrowCircleRight
          className={
            currentChild == Children.count(children) - 1
              ? "flex-none opacity-0 transition-all duration-300 size-[45px] lg:size-[60px]"
              : "flex-none opacity-60 transition-all duration-300 size-[45px] lg:size-[60px]"
          }
          onClick={handleClickRight}
        />
      </div>
    </div>
  );
}

import DefaultButton from "../components/DefaultButton";
import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { storage, database } from "../firebase-config";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { useLocation, useNavigate } from "react-router-dom";

export default function Post({ onPost }) {
  const nav = useNavigate();

  const [dbDocument, setDbDocument] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [thumbFile, setThumbFile] = useState("");
  const [imgFiles, setImgFiles] = useState("");
  const [videoUrls, setVideoUrls] = useState("");

  const { state } = useLocation();
  const { edProjId, edDbId } = state ? state : {};

  const editorRef = useRef(null);

  useEffect(() => {
    if (edProjId == null) {
      return;
    }

    const getData = async () => {
      const document = await getDoc(doc(database, `projects/${edDbId}`));
      setDbDocument(document.data());
    };

    getData();
  }, []);

  useEffect(() => {
    if (dbDocument == "") {
      return;
    }

    document.getElementById("title").value = dbDocument.title;
    setTitle(dbDocument.title);

    document.getElementById("desc").value = dbDocument.desc;
    setDesc(dbDocument.desc);

    document.getElementById("videos").value = dbDocument.videos;
    setVideoUrls(dbDocument.videos);

    if (editorRef.current) {
      editorRef.current.setContent(dbDocument.content);
    }

    setThumbFile(dbDocument.thumb);

    listAll(ref(storage, `projectFiles/${dbDocument.id}/`)).then((imgs) => {
      imgs.items.forEach((image) => {
        getDownloadURL(image).then((url) => {
          setImgFiles((images) => [...images, url]);
        });
      });
    });
  }, [dbDocument]);

  const getContent = () => {
    if (editorRef.current) {
      return editorRef.current.getContent();
    }
  };

  const dbCollection = collection(database, "projects");

  const handlePost = async () => {
    const projectId = uuidv4();

    const thumbRef = ref(
      storage,
      `projectFiles/${projectId}/thumbnail/thumbnail`
    );
    await uploadBytes(thumbRef, thumbFile);
    const thumbUrl = await getDownloadURL(thumbRef);

    Array.from(imgFiles).forEach((currentImg) => {
      const imagesRef = ref(storage, `projectFiles/${projectId}/${uuidv4()}`);
      uploadBytes(imagesRef, currentImg);
    });

    await addDoc(dbCollection, {
      id: projectId,
      address: `proj/${projectId}`,
      title: title,
      desc: desc,
      thumb: thumbUrl,
      content: getContent(),
      videos: videoUrls != [""] ? videoUrls : "",
    });

    window.alert("postado!");
    onPost();

    nav("/projects");
  };

  const handleEdit = async () => {
    const thumbRef = ref(
      storage,
      `projectFiles/${edProjId}/thumbnail/thumbnail`
    );

    if (typeof thumbFile !== "string") {
      await deleteObject(thumbRef).catch((error) => {
        console.log("thumbnail nonexistent");
      });
      await uploadBytes(thumbRef, thumbFile);
      const thumbUrl = await getDownloadURL(thumbRef);

      await updateDoc(doc(database, `projects/${edDbId}`), {
        title: title,
        desc: desc,
        content: getContent(),
        thumb: thumbUrl,
      });
    } else {
      await updateDoc(doc(database, `projects/${edDbId}`), {
        title: title,
        desc: desc,
        content: getContent(),
        videos: videoUrls != [""] ? videoUrls : "",
      });
    }

    if (typeof imgFiles[0] === "string") {
      window.alert("editado!");
      nav("/projects");
      return;
    }

    listAll(ref(storage, `projectFiles/${edProjId}/`)).then((imgs) => {
      imgs.items.forEach((image) => {
        deleteObject(image);
      });
    });

    Array.from(imgFiles).forEach((currentImg) => {
      const upload = async () => {
        const imagesRef = ref(storage, `projectFiles/${edProjId}/${uuidv4()}`);
        await uploadBytes(imagesRef, currentImg);
      };

      upload();
    });

    window.alert("editado!");
    nav("/projects");
  };

  useEffect(() => {
    console.log(videoUrls);
  }, [videoUrls]);

  return (
    <div className="text-center font-Rubik">
      <p>Título</p>
      <input
        id="title"
        type="text"
        placeholder="Digite aqui..."
        className="rounded-md border-2 w-[95%]"
        onChange={(e) => setTitle(e.target.value)}
      />

      <p>Descrição</p>
      <textarea
        id="desc"
        placeholder="Digite aqui..."
        className="rounded-md border-2 w-[95%]"
        name="description"
        cols="50"
        rows="5"
        onChange={(e) => setDesc(e.target.value)}
      ></textarea>

      <div className="m-4">
        <p>Thumbnail</p>
        <label className="text-lg m-2" htmlFor="thumbnail">
          Escolha uma imagem:{" "}
        </label>
        <input
          type="file"
          id="thumbnail"
          accept="image/*"
          onChange={(e) => setThumbFile(e.target.files[0])}
        />
        {thumbFile && (
          <img
            className="inline p-3"
            src={
              typeof thumbFile === "string"
                ? thumbFile
                : URL.createObjectURL(thumbFile)
            }
            width={100}
          />
        )}
      </div>

      <div>
        <p>Vídeos</p>
        <textarea
          id="videos"
          placeholder="Cole as urls"
          className="rounded-md border-2 w-[95%]"
          name="videos"
          cols="50"
          rows="5"
          onChange={(e) => {
            const str = e.target.value
              .trim()
              .replace(/(\s+|\n+|,+|,\s)/g, "\n");
            const finalStr = str.replace(/watch\?v=/g, "embed/").trim();

            const youTubeGetID = (url) => {
              const [a, , b] = url
                .replace(/(>|<)/gi, "")
                .split(
                  /^.*(?:(?:youtu\.?be(\.com)?\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/
                );
              if (b !== undefined) {
                return b.split(/[^0-9a-z_-]/i)[0];
              } else {
                return a;
              }
            };

            const urls = str.split("\n").filter((url) => url != "");

            setVideoUrls(
              urls.map((url) => {
                const id = youTubeGetID(url);
                return `https://www.youtube.com/embed/${id}`;
              })
            );
          }}
        ></textarea>
      </div>

      <div className="m-4">
        <p>Imagens</p>
        <label className="text-lg m-2" htmlFor="images">
          Escolha uma imagem:{" "}
        </label>
        <input
          type="file"
          id="images"
          accept="image/*"
          multiple
          onChange={(e) => setImgFiles(e.target.files)}
        />
        <div className="w-full border-4 rounded-2xl">
          {imgFiles ? (
            Array.from(imgFiles).map((url) => {
              const finalUrl =
                typeof url === "string" ? url : URL.createObjectURL(url);
              return <img className="inline p-2" width={100} src={finalUrl} />;
            })
          ) : (
            <p>Publique imagens!</p>
          )}
        </div>
      </div>

      <p>Conteúdo</p>
      <div className="text-center m-2">
        <Editor
          apiKey="ljxpu2gk0sru3p3ksj8nqziwkm4qonezkytbb4roiztlzaee"
          onInit={(_evt, editor) => (editorRef.current = editor)}
          initialValue=""
          init={{
            font_family_formats: 
              "Andale Mono=andale mono,times; " +
              "Arial=arial,helvetica,sans-serif; " +
              " Arial Black=arial black,avant garde; " +
              "Book Antiqua=book antiqua,palatino; " +
              "Comic Sans MS=comic sans ms,sans-serif; " +
              "Courier New=courier new,courier; " +
              "Georgia=georgia,palatino; Helvetica=helvetica; " +
              "Impact=impact,chicago; Symbol=symbol; " +
              " Rubik=rubik; " +
              "Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; " +
              "Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            height: 400,
            menubar: false,
            placeholder: "Digite aqui...",
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "emoticons",
              "charmap",
              "preview",
              "anchor",
              "link",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              " fontfamily fontsize bold italic forecolor | alignleft aligncenter " +
              " link emoticons " +
              " rotateleft rotateright | flipv fliph " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Rubik; font-size:14px }; " +
              "@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap'); ",
          }}
        />
        {edProjId ? (
          <DefaultButton onClick={handleEdit}>Editar</DefaultButton>
        ) : (
          <DefaultButton onClick={handlePost}>Postar</DefaultButton>
        )}
      </div>
    </div>
  );
}

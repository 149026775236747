import { Link, useNavigate } from "react-router-dom";
import DefaultButton from "./DefaultButton";
import { FaTrashAlt, FaPencilAlt, FaRegStar, FaStar } from "react-icons/fa";
import { database, storage } from "../firebase-config";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { deleteObject, listAll, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import Popup from "./Popup";
import { TbCircleNumber1, TbCircleNumber2, TbCircleNumber3 } from "react-icons/tb";
import { useAuth } from "../FirebaseHooks";

export default function Card({
  editable = true,
  dbId,
  projectId,
  img,
  title,
  description,
  update,
}) {
  const [isHighlight, setIsHighlight] = useState(false);
  const [highlightPosition, setHighlightPos] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);

  const {isLoggedIn} = useAuth();

  const navigate = useNavigate();
  const highlightPath = doc(database, "projects", "highlighted");

  const handleDelete = async () => {
    if (!window.confirm("Não há como recuperar o projeto após deletar!")) {
      return;
    }

    await deleteDoc(doc(database, "projects", dbId));
    const storageRef = ref(storage, `projectFiles/${projectId}/`);
    await listAll(storageRef).then((files) => {
      files.items.forEach((item) => {
        deleteObject(item);
      });
    });
    window.alert("deletado!");
    update();
  };

  const handleEdit = () => {
    navigate("/post", { state: { edProjId: projectId, edDbId: dbId } });
  };

  const updateIsHighlight = async () => {
    let validationRes = false;
    let dbPosition = "";
    const highlightDoc = (await getDoc(highlightPath)).data();

    for (const [key, value] of Object.entries(highlightDoc)) {
      if (value === projectId) {
        validationRes = true;
        dbPosition = key;
      }
    }

    setIsHighlight(validationRes);
    setHighlightPos(dbPosition);
  };

  useEffect(() => {
    updateIsHighlight();
  }, []);

  const handleHighlight = async (position) => {
    const newDoc = (await getDoc(highlightPath)).data();
    newDoc[position] = projectId;

    await updateDoc(highlightPath, newDoc);

    setPopupOpen(false);
    updateIsHighlight();
    update();
  };

  const handleUnhighlight = async () => {
    if (!isHighlight || highlightPosition === "") {
      return;
    }

    const newDoc = (await getDoc(highlightPath)).data();
    newDoc[highlightPosition] = "";

    await updateDoc(highlightPath, newDoc);

    updateIsHighlight();
    update();
  };

  return (
    <div className="w-full z-[inherit] relative rounded-3xl mx-auto my-7 shadow-lg border-2 border-igLightGrey bg-igWhite shadow-igGrey hover:scale-[1.02] duration-500 transition-all">
      {editable && isLoggedIn && (
        <div className=" absolute size-full ">
          <Popup
            isOpen={isPopupOpen}
            onClose={() => {
              setPopupOpen(false);
            }}
          >
            <p className="text-3xl font-bold break-words text-center">
              Escolha posição:
            </p>

            <div className="flex flex-wrap justify-center m-10">
              <DefaultButton onClick={() => handleHighlight("first")}>
                Primeiro
              </DefaultButton>
              <DefaultButton onClick={() => handleHighlight("second")}>
                Segundo
              </DefaultButton>
              <DefaultButton onClick={() => handleHighlight("third")}>
                Terceiro
              </DefaultButton>
            </div>
          </Popup>

          <div className="flex justify-end float-right gap-5 p-5 rounded-2xl rounded-tl-none rounded-br-none bg-igPink">
            {isHighlight ? (
              <div className="relative">
                <FaStar
                  className="size-10 inset-0  hover:cursor-pointer "
                  onClick={handleUnhighlight}
                />
                {highlightPosition == "first" ? (
                  <TbCircleNumber1
                    className="size-5 absolute inset-3 -mx-[2px] mt-[0.1px] fill-igPink"
                  />
                ) : highlightPosition == "second" ? (
                  <TbCircleNumber2
                    className="size-5 absolute inset-3 -mx-[2px] mt-[0.1px] fill-igPink"
                  />
                ) : highlightPosition == "third" ? (
                  <TbCircleNumber3
                    className="size-5 absolute inset-3 -mx-[2px] mt-[0.1px] fill-igPink"
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <FaRegStar
                className="size-10 hover:cursor-pointer "
                onClick={() => setPopupOpen(true)}
              />
            )}

            <FaPencilAlt
              className="size-10 hover:cursor-pointer"
              onClick={handleEdit}
            />
            <FaTrashAlt
              className="size-10 hover:cursor-pointer"
              onClick={handleDelete}
            />
          </div>
        </div>
      )}

      <img
        className="w-full h-[150px]  object-cover rounded-t-3xl border-b-2 border-igGrey"
        src={img}
        alt="not found"
      />

      <div className="text-ellipsis inline-block ">
        <h1 className="text-3xl text-ellipsis font-semibold text-start text-balance break-words break-all p-4 pb-0">
          {title}
        </h1>
        <p className="font-normal text-lg text-start text-balance p-2 mb-20 break-words break-all w-[95%]">
          {description}
        </p>
      </div>

      <div className="z-[inherit] absolute bottom-0 right-0 float-right ">
        <Link to={`/proj/${projectId}`}>
          <DefaultButton>ver mais</DefaultButton>
        </Link>
      </div>
    </div>
  );
}

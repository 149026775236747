export default function PlainButton({
  children,
  onClick
}) {
  return (
    <button
      className="hover:scale-110 rounded-3xl active:bg-black active:bg-opacity-20 active:duration-75 transition-all ease-out p-2 duration-500"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

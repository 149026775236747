import { useState } from "react";
import DefaultButton from "../components/DefaultButton";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../FirebaseHooks";
import { auth } from "../firebase-config";
import { useNavigate } from "react-router-dom";

export default function Login({}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { isLoggedIn } = useAuth();
  const nav = useNavigate();

  const handleSignIn = async () => {
    if (isLoggedIn) {
      window.alert("Já logado (a)");
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      window.alert("Sucesso!");
      nav("/");
    } catch (error) {
      window.alert("Email ou senha inválidos!");
      console.log(error);
    }
  };

  return (
    <div className="font-Rubik">
      <p className="text-5xl text-center m-5">Login</p>

      <p className="text-3xl m-2">E-mail:</p>
      <input
        className="text-2xl border-4 w-[95%] m-2"
        type="email"
        placeholder="exemplo@exemplo.com"
        onChange={(e) => setEmail(e.target.value)}
      />

      <p className="text-3xl m-2">Senha:</p>
      <input
        className="text-2xl border-4 w-[95%] m-2"
        type="password"
        placeholder="senha"
        onChange={(e) => setPassword(e.target.value)}
      />

      <div className="flex justify-center m-2">
        <DefaultButton onClick={handleSignIn}>Logar</DefaultButton>
      </div>
    </div>
  );
}
